import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { Paper, Stack } from "@mui/material";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";
import { useGetRatingAggregates } from "@src/appV2/Reviews/api/useGetRatingAggregates";

import { DialogHeader } from "../../components/DialogHeader";
import { RatingsBreakdown } from "../../Review/RatingsBreakdown";
import { ReviewsSummary } from "../../Review/ReviewsSummary";
import { WorkplaceHighlightPills } from "../Highlights/Pills";
import { useWorkplaceReviewHighlights } from "../Highlights/useWorkplaceReviewHighlights";
import { WorkplaceCommentList } from "./Comment/List";
import { WorkplaceReviewsFooter } from "./Footer";

interface WorkplaceReviewsModalProps {
  modalState: UseModalState;
  workplaceId: string;
}

export function WorkplaceReviewsModal(props: WorkplaceReviewsModalProps) {
  const { modalState, workplaceId } = props;

  const { data: ratingBreakdowns, isSuccess: ratingBreakdownsIsSuccess } = useGetRatingAggregates(
    String(workplaceId)
  );

  const { highlights: reviewHighlights, isLoading: isReviewHighlightsLoading } =
    useWorkplaceReviewHighlights({ workplaceId });

  return (
    <FullScreenDialog skipTopSafeAreaPadding modalState={modalState} variant="tertiary">
      <Stack sx={{ overflow: "auto", height: "100%" }}>
        <DialogHeader sx={{ px: 7 }} />

        {ratingBreakdownsIsSuccess && (
          <Paper variant="tertiary" sx={{ px: 7, paddingBottom: 10 }}>
            <Stack spacing={8}>
              <ReviewsSummary
                rating={ratingBreakdowns.avgRating}
                reviewsCount={ratingBreakdowns.numberOfRatings}
              />

              <Text semibold variant="h6" sx={{ paddingTop: 3 }}>
                General Rating
              </Text>

              <RatingsBreakdown ratingBreakdowns={ratingBreakdowns.ratingsBreakdown} />

              <Text semibold variant="h6" sx={{ paddingTop: 3 }}>
                Highlights
              </Text>

              <WorkplaceHighlightPills
                highlights={reviewHighlights}
                isLoading={isReviewHighlightsLoading}
              />
            </Stack>
          </Paper>
        )}

        <Paper variant="secondary" sx={{ px: 5, py: 10, flexGrow: 1 }}>
          <WorkplaceCommentList workplaceId={workplaceId} />
        </Paper>
      </Stack>

      <WorkplaceReviewsFooter workplaceId={workplaceId} />
    </FullScreenDialog>
  );
}
