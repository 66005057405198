import type { UseGetQueryOptions } from "@src/appV2/api";
import { type GeoLocation } from "@src/appV2/Location";
import { convertMetersToMiles, convertToGeoLocation } from "@src/appV2/Location/utils";
import type { Worker } from "@src/appV2/Worker/api/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { round } from "lodash";

import {
  type CalculateDistanceResponse,
  useGetDrivingDistance,
} from "../GeoLocation/useGetDrivingDistance";

interface UseDistanceToWorkplaceProps extends UseGetQueryOptions<CalculateDistanceResponse> {
  workplaceGeoLocation?: GeoLocation;
  workerGeoLocation?: GeoLocation;
}

function formatDistance(distanceInMiles: number): string {
  if (distanceInMiles < 1) {
    return "<1 mi";
  }

  // Using lodash to round to 1 decimal place, i.e. "1.2 mi" instead of "1.23 mi".
  // Math.round() would round to the nearest integer, i.e. "1 mi" instead of "1.2 mi".
  return `${round(distanceInMiles, 1)} mi`;
}

function getGeoLocationFromWorker(worker: Worker) {
  return worker.geoLocation?.coordinates
    ? convertToGeoLocation(worker.geoLocation?.coordinates)
    : undefined;
}

export function useDistanceToWorkplace(props: UseDistanceToWorkplaceProps) {
  const { workplaceGeoLocation, workerGeoLocation, ...queryOptions } = props;

  const worker = useDefinedWorker();

  const origin = workerGeoLocation ?? getGeoLocationFromWorker(worker);
  const destination = workplaceGeoLocation
    ? { latitude: workplaceGeoLocation.latitude, longitude: workplaceGeoLocation.longitude }
    : undefined;

  const { data, ...rest } = useGetDrivingDistance(
    {
      origin,
      destination,
    },
    queryOptions
  );

  const distanceInMiles = data ? convertMetersToMiles(data.distance.value) : undefined;
  const formattedDistance = distanceInMiles ? formatDistance(distanceInMiles) : undefined;

  return {
    ...rest,
    data,
    formattedDistance,
    distanceInMiles,
    // Because the query can be disabled in case worker or facility geoLocation is not defined,
    // we need to use always the isInitialLoading instead of isLoading.
    // Otherwise, it will be loading forever even if the query is disabled.
    // https://tanstack.com/query/v4/docs/framework/react/guides/disabling-queries#isinitialloading
    isLoading: rest.isInitialLoading,
  };
}
