/**
 * Workplace (Facility) Note Identifiers enum with readable names as keys.
 * The identifier values come from the Facility Notes API.
 *
 * There is a enum on src/constants/facility.ts for the note identifiers,
 * but it's incomplete and we're not able to import it here because
 * of the rule "You cannot import old app code into v2".
 */
export enum WorkplaceNoteIdentifier {
  CANCEL_INSTRUCTIONS = "CANCEL_INS",
  CHECK_IN_INSTRUCTIONS = "CHECK_IN_INS",
  DRESS_CODE_INSTRUCTIONS = "CHECK_IN_INS_DRESS",
  EARLY_CHECK_IN_INSTRUCTIONS = "CHECK_IN_INS_EARLYCHECKIN",
  ENTRANCE_INSTRUCTIONS = "CHECK_IN_INS_ENTRANCE",
  FIRST_SHIFT_INSTRUCTIONS = "CHECK_IN_INS_FIRSTSHIFT",
  MANDATORY_BREAK_POLICY = "MANDATORY_BREAK_POLICY",
  REST_BREAK_POLICY = "REST_BREAK_POLICY",
  ORIENTATION_INSTRUCTIONS = "CHECK_IN_INS_ORIENTATION",
  OTHER_INSTRUCTIONS = "CHECK_IN_INS_OTHER",
  PARKING_INSTRUCTIONS = "CHECK_IN_INS_PARKING",
  PROFILE_DESCRIPTION = "PROFILE_DESC",
  SHIFT_CONFIRMATION_INSTRUCTIONS = "SHIFT_CONFIRM_INS",
  TIMECARD_INSTRUCTIONS = "CHECK_IN_INS_TIMECARD",
}
